import React from "react"
import CardPortfolio from "../../components/our-portfolio/CardPortfolio"
import Layout from "../../components/layout"
import Banner from "../../components/Hyderabad/Banner"
import Banners from "../../components/our-portfolio/Banners"
import SEO from "../../components/seo"

const ourPortfolio = () => {
  return (
    <Layout>
      <SEO
        title="Cresol Infoserv | Best portfolio website"
        description="portfolio services online unique portfolio and website build various facets professional agencies"
        // keywords={keywords}
      />
      <div>
        <Banners />
        <CardPortfolio />
      </div>
    </Layout>
  )
}

export default ourPortfolio
